<template lang="pug">
  div.row.justify-content-end
    div.col-12
      h1.h4 Query
    div.col-12
      span Templates
      button.btn.btn-outline-primary.mx-2(type="button", @click="sql=templates.error_rate") ErrorRate
    div.col-12.mt-3
      PrismEditor.sql-editor(v-model="sql", :highlight="highlighter")
    div.col-12.mt-3
      div.row
        label.col-auto.col-form-label 'START_DATETIME'
        div.col-3
          flatPickr.form-control(v-model="start_datetime", :config="datetime_config")
        label.col-auto.col-form-label 'END_DATETIME'
        div.col-3
          flatPickr.form-control(v-model="end_datetime", :config="datetime_config")
        div.col
          button.btn.btn-outline-primary.mx-2(type="button", @click="set_24hours") 24h
          button.btn.btn-outline-primary.mx-2(type="button", @click="set_7days") 1w
    div.col-12.mt-3
      button.btn.btn-outline-primary.mt-3(type="button", @click="submit_sql") RUN
    div.col-12.mt-4
      VueGoodTable.result-table(:key="vgt_key", :columns="results.columns", :rows="results.rows", :pagination-options="pagination_options", styleClass="vgt-table condensed", :sort-options="{ enabled:true }")

</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism-tomorrow.css';
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    PrismEditor, VueGoodTable, flatPickr
  },
  data (){
    return {
      sql:"",
      start_datetime:null,
      end_datetime:null,
      datetime_config:{
        enableTime: true,
        dateFormat: "Y-m-d H:i:00+9",
        time_24hr:true
      },
      vgt_key:0,
      results: {
        columns:[],
        rows:[]
      },
      pagination_options: {
        enabled: true,
        mode: 'pages',
        position: 'both',
        perPage: 100,
        perPageDropdown: [250, 500, 1000]
      },
      templates:{
        "error_rate":`SELECT TARGET_V2.ID AS TARGET_V2_ID,
	TARGET_V2.LABEL AS TARGET_V2_LABEL,
	TARGET_V2._CHECK_TRIGGERS->0->'Value' AS CHECK_INTERVAL,
	CHK.CHECK_COUNT AS CHECK_COUNT,
-- 	"ERROR"."ERROR" AS "ERROR",
-- 	ENF.ELEMENT_NOT_FOUND AS ELEMENT_NOT_FOUND,
	ROUND(ENF.ELEMENT_NOT_FOUND * 100.0 / CHK.CHECK_COUNT,2) AS NO_ELEMENT_RATE,
	ROUND("ERROR"."ERROR" * 100.0 / CHK.CHECK_COUNT,2) AS ERROR_RATE,
	ROUND(AVGS.PROCESSING_TIME_AVG/1000, 3) AS PROCESSING_TIME_AVG
FROM TARGET_V2
LEFT OUTER JOIN
	(SELECT HISTORY_V2.TARGET_V2_ID AS TARGET_V2_ID,
			COUNT(HISTORY_V2) AS ELEMENT_NOT_FOUND
		FROM HISTORY_V2
		WHERE HISTORY_V2.CHECKED > TIMESTAMP WITH TIME ZONE 'START_DATETIME' and HISTORY_V2.CHECKED < TIMESTAMP WITH TIME ZONE 'END_DATETIME'
			AND HISTORY_V2.ELEMENT_NOT_FOUND
		GROUP BY HISTORY_V2.TARGET_V2_ID) AS ENF ON TARGET_V2.ID = ENF.TARGET_V2_ID
LEFT OUTER JOIN
	(SELECT HISTORY_V2.TARGET_V2_ID AS TARGET_V2_ID,
			COUNT(HISTORY_V2) AS "ERROR"
		FROM HISTORY_V2
		WHERE HISTORY_V2.CHECKED > TIMESTAMP WITH TIME ZONE 'START_DATETIME' and HISTORY_V2.CHECKED < TIMESTAMP WITH TIME ZONE 'END_DATETIME'
			AND HISTORY_V2.ERROR
		GROUP BY HISTORY_V2.TARGET_V2_ID) AS "ERROR" ON TARGET_V2.ID = "ERROR".TARGET_V2_ID
JOIN
	(SELECT HISTORY_V2.TARGET_V2_ID AS TARGET_V2_ID,
			COUNT(HISTORY_V2) AS CHECK_COUNT
		FROM HISTORY_V2
		WHERE HISTORY_V2.CHECKED > TIMESTAMP WITH TIME ZONE 'START_DATETIME' and HISTORY_V2.CHECKED < TIMESTAMP WITH TIME ZONE 'END_DATETIME'
		GROUP BY HISTORY_V2.TARGET_V2_ID) AS CHK ON TARGET_V2.ID = CHK.TARGET_V2_ID
JOIN
	(SELECT HISTORY_V2.TARGET_V2_ID AS TARGET_V2_ID,
			AVG(HISTORY_V2.PROCESSING_TIME) AS PROCESSING_TIME_AVG
		FROM HISTORY_V2
	 	WHERE HISTORY_V2.CHECKED > TIMESTAMP WITH TIME ZONE 'START_DATETIME' and HISTORY_V2.CHECKED < TIMESTAMP WITH TIME ZONE 'END_DATETIME'
		GROUP BY HISTORY_V2.TARGET_V2_ID) AS AVGS ON TARGET_V2.ID = AVGS.TARGET_V2_ID
WHERE TARGET_V2.VALID
ORDER BY NO_ELEMENT_RATE DESC`
      }
    }
  },
  mounted (){
    this.set_24hours();
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.sql);
    },
    async submit_sql() {
      if (this.sql.includes("commit") || this.sql.includes("COMMIT")) {
        this.$swal("Error", '"COMMIT"を含めることはできません', "error");
        return;
      }
      let data = {
        sql:this.sql,
        start:this.start_datetime,
        end:this.end_datetime
      }
      try {
        let res = await this.axios.post("/api/sql_query", data);
        this.results = res.data;
        this.vgt_key++;
      }
      catch(e){
        console.log(e);
        this.$swal("Error", "", "error");
      }
    },
    set_24hours (){
      this.end_datetime = new Date();
      this.start_datetime = new Date();
      this.start_datetime.setDate(this.start_datetime.getDate()-1);
    },
    set_7days (){
      this.end_datetime = new Date();
      this.start_datetime = new Date();
      this.start_datetime.setDate(this.start_datetime.getDate()-7);
    }
  },
};
</script>

<style lang="scss" scoped>
.sql-editor {
  // background: #2d2d2d;
  // color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

::v-deep .prism-editor__textarea:focus {
  outline: none;
}

.result-table ::v-deep {
  * {
    font-size: 12px;
  }
}
</style>